"use strict";

var _interopRequireDefault = require("/Users/tanglewang/htdocs/hsgx/anxinshouhu_h5/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("/Users/tanglewang/htdocs/hsgx/anxinshouhu_h5/node_modules/core-js/modules/es6.array.iterator.js");

require("/Users/tanglewang/htdocs/hsgx/anxinshouhu_h5/node_modules/core-js/modules/es6.promise.js");

require("/Users/tanglewang/htdocs/hsgx/anxinshouhu_h5/node_modules/core-js/modules/es6.object.assign.js");

require("/Users/tanglewang/htdocs/hsgx/anxinshouhu_h5/node_modules/core-js/modules/es7.promise.finally.js");

var _vue = _interopRequireDefault(require("vue"));

require("lib-flexible");

var _App = _interopRequireDefault(require("./App.vue"));

var _router = _interopRequireDefault(require("@/router"));

var _store = _interopRequireDefault(require("@/store"));

var _fastclick = _interopRequireDefault(require("fastclick"));

require("utils/permission");

var _SvgIcon = _interopRequireDefault(require("components/SvgIcon"));

require("@/icons");

require("@/style/common.scss");

require("@/style/colorUi.css");

require("@/style/iconfont.css");

var _settings = _interopRequireDefault(require("@/settings"));

require("@/plugins/vant");

var _helpers = _interopRequireDefault(require("./mixins/helpers"));

var _mock = require("../mock");

// icon
// 全局引入UI库 vant
// 用户tokne设置相关

/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online! ! !
 */
_vue.default.mixin(_helpers.default);

if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'development') {} // mockXHR()
// 修复点击300ms延迟


if ('addEventListener' in document) {
  document.addEventListener('DOMContentLoaded', function () {
    _fastclick.default.attach(document.body);
  }, false);
}

_fastclick.default.prototype.focus = function (targetElement) {
  targetElement.focus();
};

_vue.default.component('svg-icon', _SvgIcon.default);

if (process.env.NODE_ENV === 'development' && _settings.default.vconsole) {
  var VConsole = require('vconsole'); // eslint-disable-next-line
  // const my_console = new VConsole()

} // var vConsole = new VConsole(option)


_vue.default.config.productionTip = false;

_router.default.beforeEach(function (to, from, next) {
  if (to.meta.title) {
    document.title = to.meta.title;
  }

  next();
});

new _vue.default({
  router: _router.default,
  store: _store.default,
  render: function render(h) {
    return h(_App.default);
  }
}).$mount('#app');
_vue.default.config.devtools = true;